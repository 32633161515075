import { useLocation } from "react-router-dom";
import Wave from "react-wavify";

const Footer = () => {
  const location = useLocation();
  return (
    <footer
      className={`hyperbaric-chamber-footer-section ${
        location.pathname.includes("benefits")
          ? "hyperbaric-chamber-background-white"
          : "hyperbaric-chamber-background-grey"
      }`}
    >
      <div className="hyperbaric-chamber-footer-width-container">
        <p>
          <span className="hyperbaric-chamber-footer-developed-by">
            Developed by
          </span>{" "}
          <span className="hyperbaric-chamber-footer-developer-name">
            <a href="mailto: r.tomaszewski@gmail.com">Radosław Tomaszewski</a>
          </span>
        </p>
        <Wave
          fill="url(#gradient)"
          style={{
            display: "block",
            height: "150px",
            overflow: "hidden",
          }}
        >
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="10%" stopColor="#181842" />
              <stop offset="90%" stopColor="#26265c" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
    </footer>
  );
};

export default Footer;
