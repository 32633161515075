import "./About.css";
import SEO from "../SEO";

import DomainsState from "../../../domains/DomainsState";

const About = () => {
  const { currentHref, atmaBankPl } = DomainsState();
  return (
    <div id="About">
      <SEO
        title="O blogu"
        titleEn="About"
        description="O blogu"
        descriptionEn="About"
        keywords="kundalini, rozwój duchowy, przebudzenie kundalini, czakry, duchowość, medytacja"
        keywordsEn="kundalini, spiritual development, kundalini awakening, chakras, spirituality, meditation"
      />
      <div className="about-container">
        <div className="about-image"></div>
        <div className="about-text-container">
          <div className="about-text-wrapper">
            <h3 className="about-title">
              {currentHref === atmaBankPl ? "Witaj" : "Hi"}
            </h3>
            <p className="about-text">
              {currentHref === atmaBankPl
                ? "Cieszę się, że zawitałeś na mojej stronie. Jest to blog poświęcony tematyce rozwoju duchowego. W szczególności tematyce wciąż uważanemu za tejemnicze zjawisko przebudzenia energii Kundalini. Jako, że z racji osobistych doświadczeń jest to temat szczególnie mi bliski pragnę podzielić się tutaj swoją wiedzą i spostrzeżeniami. Kto wie być może odnajdziesz tutaj informacje, które pomogą rozwiać choć częściowo Twoje wątpliwości, które bardzo często pojawiają się wraz z przebudzeniem tej niesamowitej mocy uśpionej w każdym z nas."
                : "I'm glad you visited my website. This is a blog devoted to spiritual development. In particular, the subject that is still considered a mysterious phenomenon of the awakening of Kundalini energy. Due to my personal experience, this topic is particularly close to me, I would like to share my knowledge and observations here. Who knows, maybe you will find information here that will help dispel, at least partially, your doubts, which very often appear with the awakening of this incredible power hidden in each of us."}
            </p>
            <p className="about-text">
              {currentHref === atmaBankPl
                ? "Stronę tę dedykuję wszystkim poszukiwaczom, którzy znajdują się na duchowej ścieżce bez względu na to czy są tego świadomi czy nie. Ostatecznie wszyscy się na niej znajdujemy, bo wszyscy poszukujemy na swój indywidualny sposób spokoju i szczęścia."
                : "This page is dedicated to all seekers who are on a spiritual path, whether they are aware of it or not. Ultimately, we all find ourselves there because we all seek peace and happiness in our own individual way."}
            </p>
            <p className="about-greetings-text">
              {currentHref === atmaBankPl ? "Pozdrawiam Cię" : "I greet you"}
            </p>
            <p className="about-author-name">Radek Radhika Tomaszewski</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
