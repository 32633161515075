import { useNavigate } from "react-router-dom";

import "./SecondHeader.css";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useResetPostsHook from "../../hooks/useResetPostsHook";
import useCurrentPostDataHook from "../../hooks/useCurrentPostDataHook";
import usePostsByCategoryHook from "../../hooks/usePostsByCategoryHook";
import useScreenWidthHook from "../../hooks/useScreenWidthHook";
import useScrollToTopHook from "../../hooks/useScrollToTopHook";

const SecondHeader = (props) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { screenWidth } = AppState();
  const { category, categoryEn, postTitle, postTitleEn } = props;
  const { atmaBankPl, currentHref } = DomainsState();

  const { getResetPosts } = useResetPostsHook();
  const { getCategory, getCategoryEn } = useCurrentPostDataHook();
  const { getPostsByCategory, getPostsByCategoryEn } = usePostsByCategoryHook();
  const { GetScreenWidth } = useScreenWidthHook();
  const { scrollToTop } = useScrollToTopHook();

  GetScreenWidth();

  return (
    <div id="SecondHeader">
      <div className="second-header-container">
        <nav>
          <ul>
            <li
              style={{
                display:
                  pathName !== "/blog" && screenWidth <= 649 ? "none" : "block",
                borderLeft: "none",
                margin: "0 auto",
              }}
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
            >
              Home
            </li>
            <li
              style={{
                display:
                  pathName !== "/blog" && screenWidth <= 649 ? "none" : "block",
              }}
              onClick={() => {
                navigate("/blog");
                getResetPosts();
                getCategory();
                getCategoryEn();
                scrollToTop();
              }}
            >
              Blog
            </li>
            <li
              style={{
                display:
                  currentHref === atmaBankPl
                    ? category
                    : categoryEn === undefined ||
                      (pathName !== "/blog" && screenWidth <= 649)
                    ? "none"
                    : "block",
                borderLeft: category === "" ? "none" : "1px solid white",
              }}
              onClick={() => {
                navigate("/blog");
                currentHref === atmaBankPl
                  ? getPostsByCategory(category)
                  : getPostsByCategoryEn(categoryEn);
                scrollToTop();
              }}
            >
              {currentHref === atmaBankPl ? category : categoryEn}
            </li>
            <li
              style={{
                display: postTitle === undefined ? "none" : "block",
                borderLeft:
                  pathName !== "/blog" && screenWidth <= 649 ? "none" : "",
                margin:
                  pathName !== "/blog" && screenWidth <= 649 ? "0 auto" : "",
              }}
            >
              {currentHref === atmaBankPl ? postTitle : postTitleEn}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SecondHeader;
