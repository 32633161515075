import { useNavigate } from "react-router-dom";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useScrollToTopHook from "../../hooks/useScrollToTopHook";

const RecentPosts = () => {
  const navigate = useNavigate();
  const { atmaBankPl, currentHref } = DomainsState();
  const { postsMainBase } = AppState();
  const { scrollToTop } = useScrollToTopHook();

  return (
    <div id="RecentPosts" className="right-column-box">
      <div className="recent-posts-container right-column-box-container">
        <div className="recent-posts-title right-column-box-title">
          <h3>
            {currentHref === atmaBankPl ? "Aktualne posty" : "Recent posts"}
          </h3>
        </div>
        <nav>
          <ul>
            {postsMainBase
              .slice(0)
              .reverse()
              .map((post) => {
                return (
                  <li
                    onClick={() => {
                      navigate(
                        `/blog/${
                          currentHref === atmaBankPl ? post.slug : post.slug_en
                        }`
                      );
                      scrollToTop();
                    }}
                    key={post.id}
                  >
                    {currentHref === atmaBankPl ? post.title : post.title_en}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default RecentPosts;
