import React from "react";
import "./Home.css";
import SEO from "../SEO";

import DomainsState from "../../../domains/DomainsState";

const Home = () => {
  const { currentHref, atmaBankPl } = DomainsState();
  return (
    <div id="Home">
      <SEO
        title="AtmaBank"
        titleEn="AtmaBank"
        description="Strona o rozwoju duchowym i budzeniu energii Kundalini"
        descriptionEn="A website about spiritual development and awakening Kundalini energy"
        keywords="kundalini, rozwój duchowy, przebudzenie kundalini, czakry, duchowość, medytacja"
        keywordsEn="kundalini, spiritual development, kundalini awakening, chakras, spirituality, meditation"
      />
      <div className="home-container">
        <div className="home-text-container">
          <h3 className="home-text-title">Atma Bank</h3>
          <h5 className="home-text-undertitle">
            {currentHref === atmaBankPl
              ? "Jedyne źródło zasobów jest w Tobie"
              : "The only source of resources is within you"}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Home;
