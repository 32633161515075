import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useShowSendAnswerFormHook from "../../hooks/useShowSendAnswerFormHook";
import useCommentTimeInPolishHook from "../../hooks/useCommentTimeInPolishHook";
import useRemoveCommentHook from "../../hooks/useRemoveCommentHook";

const BlogpostCommentDateAndButtonWrapper = (props) => {
  const { isLoggedIn } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const { showSendAnswerForm } = useShowSendAnswerFormHook();
  const { getCommentTimeInPolish, getCommentTimeInEnglish } =
    useCommentTimeInPolishHook();
  const { removeComment } = useRemoveCommentHook();
  const comment = props.comment;

  return (
    <div className="blogpost-comment-date-and-button-wrapper">
      <div className="blogpost-comment-date">
        <p>
          {currentHref === atmaBankPl
            ? getCommentTimeInPolish(new Date(comment.commentTime))
            : getCommentTimeInEnglish(new Date(comment.commentTime))}
        </p>
      </div>
      <div className="blogpost-comment-button">
        <p
          className="add-comment-form-button comment-button"
          onClick={(e) => showSendAnswerForm(comment.id)}
        >
          {currentHref === atmaBankPl ? "Odpowiedz" : "Answer"}
        </p>
        {isLoggedIn ? (
          <p
            className="add-comment-form-button comment-button"
            onClick={() => removeComment(comment._id)}
          >
            Usuń
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default BlogpostCommentDateAndButtonWrapper;
