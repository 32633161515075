import { gql } from "@apollo/client";

const GET_CURRENT_POST_EN = gql`
  query BlogPost($slug: String!) {
    blogPosts(where: { slug_en: $slug }) {
      id
      title_en
      description_en
      keywords_en
      slug_en
      categories {
        id
        name_en
      }
      image {
        id
        url
        fileName
      }
      text_en {
        text
        html
      }
    }
  }
`;

export default GET_CURRENT_POST_EN;
