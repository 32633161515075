import { Helmet } from "react-helmet";

import DomainsState from "../../../domains/DomainsState";

const SEO = ({
  title,
  titleEn,
  description,
  descriptionEn,
  keywords,
  keywordsEn,
}) => {
  const { currentHref, atmaBankPl } = DomainsState();

  return (
    <Helmet>
      <title>{currentHref === atmaBankPl ? title : titleEn}</title>
      <meta
        name="description"
        content={currentHref === atmaBankPl ? description : descriptionEn}
      />
      <meta
        name="keywords"
        content={currentHref === atmaBankPl ? keywords : keywordsEn}
      />
    </Helmet>
  );
};

export default SEO;
