const useCommentTimeInPolishHook = () => {
  const getCommentTimeInPolish = (time) => {
    const currentTime = `${time.getHours()}:${time.getMinutes()}`; /// tutaj dokończyć
    let dateInPolish = new Intl.DateTimeFormat("pl-PL", {
      dateStyle: "full",
    }).format(time);
    return `${dateInPolish} o ${currentTime}`;
  };
  const getCommentTimeInEnglish = (time) => {
    const currentTime = `${time.getHours()}:${time.getMinutes()}`; /// tutaj dokończyć
    let dateInEnglish = new Intl.DateTimeFormat("en-EN", {
      dateStyle: "full",
    }).format(time);
    return `${dateInEnglish} at ${currentTime}`;
  };
  return { getCommentTimeInPolish, getCommentTimeInEnglish };
};

export default useCommentTimeInPolishHook;
