import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

const useScrollToCommentsHook = () => {
  const navigate = useNavigate();
  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToComment = async (target, slug) => {
    if (window.location.pathname !== `/blog/${slug}`) {
      await navigate(`/blog/${slug}`);
    }
    const interval = setInterval(() => {
      scrollTarget(target);
      if (document.getElementById(`${target}`) !== null) {
        clearInterval(interval);
      }
    }, 100);
  };

  return { scrollToComment };
};

export default useScrollToCommentsHook;
