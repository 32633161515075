import AddCommentForm from "./AddCommentForm";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useShowCommentButtonHook from "../../hooks/useShowCommentButtonHook";
import useSendCommentHook from "../../hooks/useSendCommentHook";

const BlogpostAddCommentFormContainer = () => {
  const { mainCommentsFormVisibility } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const { showCommentButton } = useShowCommentButtonHook();
  const { sendComment } = useSendCommentHook();

  return (
    <div className="blogpost-add-comment-form-container">
      <div className="blogpost-add-comment-form-title">
        <h5>
          {currentHref === atmaBankPl ? "Skomentuj artykuł" : "Comment article"}
        </h5>
        {mainCommentsFormVisibility === false ? (
          <p className="add-comment-form-button" onClick={showCommentButton}>
            {currentHref === atmaBankPl ? "Skomentuj" : "Comment"}
          </p>
        ) : (
          ""
        )}
      </div>
      {mainCommentsFormVisibility === true ? (
        <AddCommentForm sendComment={sendComment} />
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogpostAddCommentFormContainer;
