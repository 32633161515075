import AppState from "./AppState";

const usePostsByCategoryHook = () => {
  const { postsMainBase, setPosts } = AppState();
  const getPostsByCategory = (categoryName) => {
    let postsByCategory = postsMainBase.filter((post) => {
      return post.categories[0].name === categoryName;
    });
    setPosts(postsByCategory);
  };
  const getPostsByCategoryEn = (categoryName) => {
    let postsByCategory = postsMainBase.filter((post) => {
      return post.categories[0].name_en === categoryName;
    });
    setPosts(postsByCategory);
  };
  return { getPostsByCategory, getPostsByCategoryEn };
};

export default usePostsByCategoryHook;
