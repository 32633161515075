import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useFormChangeHook from "../../hooks/useFormChangeHook";
import useSendContactFormHook from "../../hooks/useSendContactFormHook";

import SEO from "../SEO";

import "./Contact.css";

const Contact = () => {
  const {
    name,
    setName,
    nameError,
    email,
    setEmail,
    emailError,
    subject,
    setSubject,
    subjectError,
    text,
    setText,
    textError,
    sendMessageSuccess,
  } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const { nameChange, emailChange, subjectChange, textChange } =
    useFormChangeHook();
  const { sendContactForm } = useSendContactFormHook();
  return (
    <div id="Contact">
      <SEO
        title="Kontakt"
        titleEn="Contact"
        description="Kontakt"
        descriptionEn="Contact"
        keywords="kundalini, rozwój duchowy, przebudzenie kundalini, czakry, duchowość, medytacja"
        keywordsEn="kundalini, spiritual development, kundalini awakening, chakras, spirituality, meditation"
      />
      <div className="contact-container">
        <div className="contact-title">
          {sendMessageSuccess ? (
            <h5
              style={{
                textAlign: "center",
                paddingTop: "10vh",
                paddingBottom: "18vh",
              }}
            >
              {currentHref === atmaBankPl
                ? "Wiadomość wysłana pomyślnie"
                : "Message sent successfully"}
            </h5>
          ) : (
            <h5>
              {currentHref === atmaBankPl
                ? "Skontaktuj się ze mną"
                : "Contact with me"}
            </h5>
          )}
        </div>
        {sendMessageSuccess === false ? (
          <form>
            <input
              style={{ color: name === nameError ? "red" : "" }}
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => nameChange(e.target.value)}
              onFocus={() => (name === nameError ? setName("") : "")}
              placeholder={currentHref === atmaBankPl ? "Imię" : "Name"}
            />
            <input
              style={{ color: email === emailError ? "red" : "" }}
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => emailChange(e.target.value)}
              onFocus={() => (email === emailError ? setEmail("") : "")}
            />
            <input
              style={{ color: subject === subjectError ? "red" : "" }}
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => subjectChange(e.target.value)}
              onFocus={() => (subject === subjectError ? setSubject("") : "")}
              placeholder={currentHref === atmaBankPl ? "Temat" : "Subject"}
            />
            <textarea
              style={{ color: text === textError ? "red" : "" }}
              id="message"
              name="message"
              placeholder={
                currentHref === atmaBankPl
                  ? "Napisz coś..."
                  : "Write something..."
              }
              value={text}
              onChange={(e) => textChange(e.target.value)}
              onFocus={() => (text === textError ? setText("") : "")}
            />
            <p onClick={sendContactForm}>
              {currentHref === atmaBankPl ? "Wyślij" : "Send"}
            </p>
          </form>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Contact;
