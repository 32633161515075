import SEO from "../SEO/SEO";

import Slider from "./Slider";
import About from "./About";
import PriceList from "./PriceList";
import Benefits from "./Benefits";
import LatestReviews from "./LatestReviews";
import Contact from "./Contact";

import AppState from "../../hooks/AppState";

const Home = (props) => {
  const { homeRef } = AppState();
  const googleReviews = props.googleReviews;
  return (
    <div ref={homeRef} id="Home">
      <SEO
        title="Terapia tlenowa"
        description="Terapia tlenowa w Jeleniej Górze"
        keywords="terapia tlenowa, tlenoterapia, komora hiperbaryczna, komora tlenowa, komora tlenowa jelenia góra"
      />
      <Slider />
      <About />
      <PriceList />
      <Benefits />
      <LatestReviews googleReviews={googleReviews} />
      <Contact />
    </div>
  );
};

export default Home;
