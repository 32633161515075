import DomainsState from "../../../domains/DomainsState";

const BlogpostPostContainer = (props) => {
  const { atmaBankPl, currentHref } = DomainsState();
  const currentPost = props.currentPost;

  return (
    <div className="blogpost-post-container">
      <div className="blogpost-post-image">
        <img
          src={currentPost.image[0].url}
          alt={currentPost.image[0].fileName}
        ></img>
      </div>
      <div className="blogpost-post-text">
        <h5 className="blogpost-post-title">
          {currentHref === atmaBankPl
            ? currentPost.title
            : currentPost.title_en}
        </h5>
        <div
          dangerouslySetInnerHTML={{
            __html:
              currentHref === atmaBankPl
                ? currentPost.text.html
                : currentPost.text_en.html,
          }}
        />
      </div>
    </div>
  );
};

export default BlogpostPostContainer;
