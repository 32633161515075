import { useNavigate } from "react-router-dom";

import "./Footer.css";

import useScrollToTopHook from "../../hooks/useScrollToTopHook";

const Footer = () => {
  const navigate = useNavigate();
  const { scrollToTop } = useScrollToTopHook();
  return (
    <div id="Footer">
      <div className="footer-container">
        <p
          onClick={() => {
            navigate("/kontakt");
            scrollToTop();
          }}
        >
          Designed by <span>Atma Bank</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
