import AppState from "./DomainsState";

const useGetCurrentHrefHook = () => {
  const { setCurrentHref } = AppState();
  const getCurrentHref = () => {
    const currentHref = window.location.host;
    setCurrentHref(currentHref);
  };

  return { getCurrentHref };
};

export default useGetCurrentHrefHook;
