import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";

const BlogpostCommentsQuantityContainer = () => {
  const { currentPostCommentsQty } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();

  return (
    <div className="blogpost-comments-quantity-container">
      <div className="blogpost-comments-quantity-text">
        {currentHref === atmaBankPl ? (
          <h5>{`${currentPostCommentsQty} komentarz${
            currentPostCommentsQty === 0
              ? "y"
              : currentPostCommentsQty < 2
              ? ""
              : currentPostCommentsQty <= 4
              ? "e"
              : "y"
          }`}</h5>
        ) : (
          <h5>{`${currentPostCommentsQty} comment${
            currentPostCommentsQty === 0
              ? "s"
              : currentPostCommentsQty < 2
              ? ""
              : currentPostCommentsQty <= 4
              ? "s"
              : ""
          }`}</h5>
        )}
      </div>
    </div>
  );
};

export default BlogpostCommentsQuantityContainer;
