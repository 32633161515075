import AppState from "./AppState";

const useCurrentPostDataHook = () => {
  const {
    setCategory,
    setCategoryEn,
    setPostTitle,
    setPostTitleEn,
    setPostID,
    setCurrentPostSlug,
    setCurrentPostSlugEn,
  } = AppState();

  const getCategory = (category) => setCategory(category);
  const getCategoryEn = (categoryEn) => setCategoryEn(categoryEn);
  const getPostTitle = (postTitle) => setPostTitle(postTitle);
  const getPostTitleEn = (postTitleEn) => setPostTitleEn(postTitleEn);
  const getCurrentPostID = (postID) => setPostID(postID);
  const getCurrentPostSlug = (postSlug) => setCurrentPostSlug(postSlug);
  const getCurrentPostSlugEn = (postSlugEn) => setCurrentPostSlugEn(postSlugEn);

  return {
    getCategory,
    getCategoryEn,
    getPostTitle,
    getPostTitleEn,
    getCurrentPostID,
    getCurrentPostSlug,
    getCurrentPostSlugEn,
  };
};

export default useCurrentPostDataHook;
