import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import "./index.css";
import { BrowserRouter, Switch, Route, Routes } from "react-router-dom";
import Axios from "axios";

import HyperbaricChamberHeader from "./hyperbaric-chamber/components/Header";
import HyperbaricChamberHome from "./hyperbaric-chamber/components/Home";
import HyperbaricChamberBenefit from "./hyperbaric-chamber/components/Benefit";
import HyperbaricChamberFooter from "./hyperbaric-chamber/components/Footer";

import AppState from "./hyperbaric-chamber/hooks/AppState";
import useGoogleReviewsSliderHook from "./hyperbaric-chamber/hooks/useGoogleReviewsSliderHook";

import ALLPOSTSQUERY from "../src/atmabank/queries/AllPostsQuery";

import AtmabankHeader from "./atmabank/components/Header";
import AtmabankHome from "./atmabank/components/Home";
import AtmabankAbout from "./atmabank/components/About";
import AtmabankBlog from "./atmabank/components/Blog";
import AtmabankContact from "./atmabank/components/Contact";
import AtmabankBlogPost from "./atmabank/components/BlogPost";
import AtmabankFooter from "./atmabank/components/Footer/Footer";
import AtmabankLogin from "./atmabank/components/Login";

import UnderConstruction from "./atmabank/components/UnderConstruction/UnderConstruction";

import DomainsState from "./domains/DomainsState";
import useGetCurrentHrefHook from "./domains/useGetCurrentHrefHook";
import usePostsHook from "./atmabank/hooks/usePostsHook";

const App = () => {
  const {
    komoraHiperbarycznaJeleniaGoraPl,
    atmaBankPl,
    atmaBankCom,
    currentHref,
  } = DomainsState();

  const {
    googleReviews,
    setGoogleReviews,
    currentSlug,
    benefits,
    currentBenefitData,
    appRef,
  } = AppState();
  const { changeReviewsSlides } = useGoogleReviewsSliderHook();
  useEffect(() => {
    Axios.post("/getReviews")
      .then((res) => {
        setGoogleReviews(res.data.reversedReviews);
        changeReviewsSlides(res.data.reversedReviews);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { getCurrentHref } = useGetCurrentHrefHook();
  const { getPosts } = usePostsHook();
  getCurrentHref();

  const { error, loading } = useQuery(ALLPOSTSQUERY, {
    onCompleted: (data) => {
      getPosts(data.blogPosts);
    },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div id="App" ref={appRef}>
      {currentHref === komoraHiperbarycznaJeleniaGoraPl ? (
        <BrowserRouter>
          <HyperbaricChamberHeader />
          <Routes>
            <Route
              path="/"
              exact
              element={<HyperbaricChamberHome googleReviews={googleReviews} />}
            />
            <Route
              path="/benefits/:currentSlug"
              element={
                <HyperbaricChamberBenefit
                  currentBenefitData={currentBenefitData}
                />
              }
            />
          </Routes>
          <HyperbaricChamberFooter />
        </BrowserRouter>
      ) : (
        // <UnderConstruction />
        <BrowserRouter>
          <AtmabankHeader />
          <Routes>
            <Route path="/" exact element={<AtmabankHome />} />
            <Route
              path={currentHref === atmaBankPl ? "/o-blogu" : "/about"}
              element={<AtmabankAbout />}
            />
            <Route path="/blog" exact element={<AtmabankBlog />} />
            <Route
              path={currentHref === atmaBankPl ? "/kontakt" : "/contact"}
              element={<AtmabankContact />}
            />
            <Route path="/blog/:slug" element={<AtmabankBlogPost />} />
            <Route path="/administrator" element={<AtmabankLogin />} />
          </Routes>
          <AtmabankFooter />
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
