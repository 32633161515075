import { useNavigate } from "react-router-dom";

import Logo from "./Logo";
import Navigation from "./Navigation";
import SecondHeader from "./SecondHeader";

import AppState from "../../hooks/AppState";

const Header = (props) => {
  const { category, categoryEn, postTitle, postTitleEn } = AppState();

  const secondHeaderRender = () => {
    if (window.location.pathname.includes("/blog")) {
      return (
        <SecondHeader
          category={category}
          categoryEn={categoryEn}
          postTitle={postTitle}
          postTitleEn={postTitleEn}
        />
      );
    } else return null;
  };

  return (
    <>
      <Logo />
      <Navigation />
      {secondHeaderRender()}
    </>
  );
};

export default Header;
