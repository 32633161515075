import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  komoraHiperbarycznaJeleniaGoraPl: "komorahiperbarycznajeleniagora.pl",
  atmaBankPl: "atmabank.pl",
  atmaBankCom: "atmabank.com",
  currentHref: "",
};

const { useGlobalState } = createGlobalState(initialState);

const AppState = () => {
  const [komoraHiperbarycznaJeleniaGoraPl] = useGlobalState(
    "komoraHiperbarycznaJeleniaGoraPl"
  );
  const [atmaBankPl] = useGlobalState("atmaBankPl");
  const [atmaBankCom] = useGlobalState("atmaBankCom");
  const [currentHref, setCurrentHref] = useGlobalState("currentHref");

  return {
    komoraHiperbarycznaJeleniaGoraPl,
    atmaBankPl,
    atmaBankCom,
    currentHref,
    setCurrentHref,
  };
};

export default AppState;
