import { useNavigate } from "react-router-dom";

import DomainsState from "../../../domains/DomainsState";
import useUniqueCategoriesHook from "../../hooks/useUniqueCategoriesHook";
import useCurrentPostDataHook from "../../hooks/useCurrentPostDataHook";
import usePostsByCategoryHook from "../../hooks/usePostsByCategoryHook";
import useScrollToTopHook from "../../hooks/useScrollToTopHook";

const PostsCategories = () => {
  const navigate = useNavigate();
  const { atmaBankPl, currentHref } = DomainsState();
  const { getUniqueCategories } = useUniqueCategoriesHook();
  const { getCategory, getCategoryEn } = useCurrentPostDataHook();
  const { getPostsByCategory, getPostsByCategoryEn } = usePostsByCategoryHook();
  const { scrollToTop } = useScrollToTopHook();

  return (
    <div id="PostsCategories" className="right-column-box">
      <div className="posts-categories-container right-column-box-container">
        <div className="posts-categories-title right-column-box-title">
          <h3>{currentHref === atmaBankPl ? "Kategorie" : "Categories"}</h3>
        </div>
        <nav>
          <ul>
            {getUniqueCategories().map((category, index) => {
              return (
                <li
                  onClick={() => {
                    navigate("/blog");
                    {
                      currentHref === atmaBankPl
                        ? getPostsByCategory(category.name)
                        : getPostsByCategoryEn(category.name_en);
                    }
                    currentHref === atmaBankPl
                      ? getCategory(category.name)
                      : getCategoryEn(category.name_en);
                    scrollToTop();
                  }}
                  key={index}
                >
                  {currentHref === atmaBankPl
                    ? category.name
                    : category.name_en}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default PostsCategories;
